.withdraw {
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  @include respond("not-phone") {
    width: 40vw;
  }
  .card {
    border-radius: 0 !important;
    height: 60px;
  }
  button {
    margin-right: 5px !important;
    margin-bottom: 200px;
  }
}
