.profile {
  background-color: $color-pink;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  position: relative;
  margin-bottom: 10rem;
  overflow: scroll;
  &__id-badge {
    position: absolute;
    left: 0;
    top: 10px;
    padding: 1rem;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    background-color: $color-tertiary-light;
  }
  &__header {
    background-image: url("../../images/room-bg.png") !important;
    background-size: contain;
    background-color: #7f120b;
    background-size: 30%;
    background-position: center;
    height: 300;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: relative;
    p {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    }
    &_balance {
      bottom: -50px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      padding: 1rem;
      background-color: #f2eae9;
      position: relative;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
      @include respond("phone") {
        padding: 1rem;
        width: 98%;
        h3 {
          font-size: 1.5rem;
        }
        h6 {
          font-size: 1.2rem;
          color: $color-blood-red;
        }
      }
      span {
        color: $color-blood-red;
        margin-left: 5px;
      }
      &-asset {
        display: flex;
        align-items: center;
      }
      &-vip {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $color-blood-red;
        padding: 5px 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
      }
    }
    &_buttons {
      margin-top: 6rem;
      display: flex;
      justify-content: space-around;
      color: white;
      a {
        background-color: white;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        svg {
          align-self: center;
        }
      }
      .button {
        max-width: 7rem;
      }
      p {
        color: $color-black;
      }
    }
  }
  &__menu {
    padding: 10px;
    hr {
      display: block;
      width: 90%;
      margin: auto !important;
      color: grey;
    }
  }

  @include respond("not-phone") {
    width: 40vw;
  }
  .card {
    border-radius: 10px !important;
  }
  button {
    margin-right: 5px !important;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .page-link {
    height: 50px;
    margin: 5px 5px 5px 20px;
    text-align: center;
    svg.profile-icon {
      font-size: 25px;
      margin-top: auto;
      margin-bottom: auto;
    }
    svg.arrow-icon {
      font-size: 22px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 10px;
    }
    p.link-title {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
    }
    span {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  hr {
    margin: 0;
  }
  .profile_header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: lightblue;
    @include respond("not-phone") {
      width: 40vw;
    }
    .background-layer {
      border-top: 1px solid lightgray;
      height: 50px;
      margin-left: 10px;
      @include respond("not-phone") {
        width: 38.5vw;
        margin-right: 30.4%;
        height: 50px;
      }
    }
    .content {
      margin-top: 5px;
    }
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-radius: 5px;
  }
  &__child {
    height: 90vh;
    background-color: $color-pink;
    .card {
      height: 90% !important;
    }
  }
}
.security {
  height: 94vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $color-pink;
  img.security-img {
    align-self: center;
    @include respond("phone") {
      width: 50%;
    }
  }
  &-input {
    align-self: center;
    width: 50%;
    display: grid;
    grid-template-columns: 16% 16% 16% 16% 16% 16%;
    justify-content: space-between;
    @include respond("phone") {
      width: 90%;
    }
    &__box {
      width: 8rem;
      height: 8rem;
      background-color: white;
      display: flex;
      align-items: center;
      border-radius: 10px;
      @include respond("phone") {
        width: 5rem;
        height: 5rem;
      }
      &_text {
        align-self: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 2rem;
      }
    }
  }
  &-button {
    width: 50%;
    align-self: center;
    @include respond("phone") {
      width: 80%;
    }
  }
}

.settings {
  height: 94vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $color-pink;
  &-logout {
    position: fixed;
    bottom: 5px;
    width: 100%;
  }
  a {
    text-decoration: none;
    color: black;
  }
  &-menu {
    height: 50px;
    margin: 10px 10px 10px 20px;
    padding-left: 10px;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    svg.profile-icon {
      font-size: 25px;
      margin-top: auto;
      margin-bottom: auto;
    }
    svg.arrow-icon {
      font-size: 22px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 10px;
    }
    p.link-title {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
    }
  }
}
