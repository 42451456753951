.support {
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  @include respond("not-phone") {
    width: 40vw;
  }
  .card {
    border-radius: 0 !important;
  }
}
.chat_header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: lightblue;
  @include respond("not-phone") {
    width: 40vw;
  }
  .background-layer {
    border-top: 1px solid lightgray;
    height: 50px;
    margin-left: 10px;
    @include respond("not-phone") {
      width: 38.5vw;
      margin-right: 30.4%;
      height: 50px;
    }
  }
  .content {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
}
.chat_container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 95%;
  margin-top: 50px;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 6rem;
  .chat_item {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    .card {
      padding: 0.5rem 1rem;
      border: none !important;
      &.my-card {
        border-radius: 20px 0px 30px 20px !important;
      }
      &.admin-card {
        border-radius: 0px 20px 30px 20px !important;
      }
    }
    .avatar {
      img {
        height: 3rem;
        border-radius: 50%;
      }
    }
    p {
      margin: 0 10px;
    }
    .text-body {
      span {
        font-size: $font-small;
      }
    }
  }
  .my_msg {
    text-align: right;
    float: right;
    margin-right: 0;
  }
}

.chat_input {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  background-color: white;
  width: 100vw;
  padding-bottom: 5px;
  @include respond("not-phone") {
    height: 80px;
  }
  p {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: auto;
    @include respond("not-phone") {
      font-size: 16px;
      margin-top: 5px;
    }
  }

  .background-layer {
    border-top: 1px solid lightgray;
    height: 50px;
    @include respond("not-phone") {
      width: 38.5vw;
      margin-left: 30.4%;
      height: 60px;
    }
    .item {
      padding-top: 3px;
      cursor: pointer;
      svg.footer-con {
        display: block;
        margin: auto;
      }
    }
  }
  .emoji {
    padding: 10px;
    cursor: pointer;
  }
}
