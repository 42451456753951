.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.landing {
  background-image: url("../../images/landing-bg.jpeg");
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;

  @include respond("not-phone") {
    width: 40%;
    margin: auto;
    height: 600px;
  }
  .change-language {
    color: white;
    padding-top: 10px;
    padding-left: 40%;
    display: flex;
    p {
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
    }
    @include respond("not-phone") {
      padding-top: 10px;
      padding-left: 50%;
    }
  }
}

.button-card {
  border: 1px solid white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -200px;
  text-align: center;
  @media only screen and (min-width: 600px) {
    width: 20%;
  }
  .item {
    margin-top: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    color: white;
  }
}
.icon {
  margin-top: 130px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
