.game {
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  font-size: $font-small;
  p {
    font-size: $font-small;
  }
  @include respond("not-phone") {
    width: 40vw;
    p {
      font-size: 16px;
    }
    span {
      font-size: 16px;
    }
  }
  .info-card {
    background-color: orange;
    p {
      font-size: 1.2rem;
      @include respond("not-phone") {
        font-size: 1.5rem;
      }
    }
    h6 {
      font-size: 1.4rem;
      @include respond("not-phone") {
        font-size: 1.8rem;
      }
    }
  }
  .user-detail {
    margin-left: 10px;
    p {
      line-height: 1.4;
      font-size: $font-small;
      // text-shadow: 4px 3px 2px rgba(136, 127, 127, 0.3);
      color: black;
    }
    h4 {
      // text-shadow: 4px 3px 2px rgba(136, 127, 127, 0.3);
      color: black;
    }
  }
  @include respond("not-phone") {
    .user-detail {
      margin-left: 15px;
      p {
        line-height: 0.8;
        font-size: $font-medium;
      }
    }
  }
  .card {
    border-radius: 0 !important;
    border: none !important;
  }
  .lottery-result {
    margin-left: 5px;
    @include respond("not-phone") {
      margin-left: 100px;
    }
  }
  .circle {
    background-color: orange;
    border-radius: 50%;
    color: white;
    height: 20px;
    width: 20px;
    display: inline-block;
    line-height: 20px;
    @include respond("not-phone") {
      height: 30px;
      width: 30px;
      line-height: 30px;
    }
  }
  .result-text {
    height: 30px;
    width: 30px;
    display: inline-block;
  }
  .equal {
    background-color: orange;
  }
  &__header {
    background-color: $color-header-background;
    height: 50px;
    color: white;
    @include respond("not-phone") {
      span.assets {
        margin-left: 30%;
      }
    }
  }
  &__bet-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 320px;
    background-color: #e8f9fd;
    .bet-item {
      padding: 10px;
      margin: 10px 0;
      display: grid;
      align-items: end;
      .card {
        border-radius: 5px !important;
        border: none !important;
        width: 80%;
      }
      .right-card {
        margin-left: 20%;
      }
      .avatar {
        padding-left: 30px;
        img {
          margin-top: 20%;
          height: 6rem;
          width: 6rem;
          border-radius: 50%;
          border: 1px solid white;
          @include respond("phone") {
            height: 5rem;
            width: 5rem;
            margin-top: 40%;
            padding: 0px;
          }
        }
        @include respond("phone") {
          padding-left: 0;
        }
      }
      p {
        font-size: $font-normal;
      }
      p.right {
        text-align: right;
      }
    }
    span.marquee,
    span {
      font-size: 1.8rem;
      @include respond("phone") {
        font-size: 1.2rem;
      }
    }
    tr.d-flex.justify-content-between {
      font-size: 1.8rem;
      @include respond("phone") {
        font-size: 1.2rem;
      }
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: orange;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 40%;
    overflow: scroll;
    @include respond("not-phone") {
      width: 40vw;
      margin-left: 30%;
      overflow: unset;
    }
    p {
      font-size: $font-normal;
      margin-top: 8px;
      margin-bottom: auto;
      @include respond("not-phone") {
        font-size: $font-medium;
        margin-top: 5px;
        font-weight: 500;
      }
    }
    .button-container {
      a.nav-link {
        color: white;
        font-size: 1.8rem;
        @include respond("phone") {
          font-size: 1.4rem;
        }
      }
      a.nav-link.active {
        border-bottom: solid 2px white;
      }
      .col-md-3.col-3 {
        padding: 2px;
      }
      .col-md-6.col-6 {
        padding: 2px;
      }
    }
    span {
      font-size: 12px;
      @include respond("not-phone") {
        font-size: 16px;
      }
    }
    button.custom_btn {
      border: none;
      padding: 5px;
      border-radius: 0.25rem;
      width: 100%;
      margin: 0 !important;
      &.selected {
        color: white;
        background-color: $color-secondary-dark;
        border: 0.5px solid white;
      }
      &.unselected {
        color: white;
        border: 0.5px solid white;
        background-color: black;
      }
      h6 {
        @include respond("not-phone") {
          font-size: 1.8rem;
          @include respond("phone") {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
