.default-header {
  background-color: $color-pink;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  @include respond("phone") {
    padding: 1rem;
  }
  svg {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    color: $color-grey-dark-3;
    font-size: 3rem;
    @include respond("phone") {
      font-size: 2.5rem;
    }
  }
  &__title {
    font-size: 1.8rem;
    @include respond("phone") {
      font-size: 1.5rem;
    }
  }
}

.asset-header {
  background-color: orange;
}
