.room {
  background-image: url("../../images/room-bg.png") !important;
  background-size: contain;
  background-color: #7f120b;
  background-size: 30%;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  &_header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-image: url("../../images/room-bg.png") !important;
    background-size: contain;
    background-color: #7f120b;
    background-size: 30%;
    @include respond("not-phone") {
      width: 40vw;
    }
    .background-layer {
      height: 50px;
      margin-left: 10px;
      @include respond("not-phone") {
        width: 38.5vw;
        margin-right: 30.4%;
        height: 50px;
      }
    }
    .content {
      display: flex;
      margin-top: 5px;
      h2 {
        padding: 1rem 2rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__body {
    margin-top: 5rem;
    padding: 2rem;
    position: relative;
    top: 10rem;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: $color-pink;
    height: 100vh;
    &_card {
      background-color: white;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 2rem;
      height: 100vh;
      &-instruction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        h3 {
          margin-left: 5px;
        }
        &-btn {
          padding: 0.8rem 1rem;
          border-bottom-left-radius: 50px;
          border-top-left-radius: 50px;
          background-color: $color-tertiary-light;
          display: flex;
          background: #e75551;
          background: linear-gradient(0deg, #e75551, #f39e11);
        }
      }
      p {
        font-size: 1.2rem;
      }
    }
    .room-card {
      height: 20rem;
      border-radius: 3rem !important;
      padding: 0;
      margin-bottom: 5px;
      cursor: pointer;
      @include respond("phone") {
        height: 15rem;
      }
      .card {
        height: 20rem !important;
        padding: 0 !important;
        margin: 0 !important;
        background: transparent !important;
        border: none;
      }
      img.room-img {
        padding: 0 !important;
        top: 0;
        position: absolute;
        width: 100%;
        height: 20rem;
        @include respond("phone") {
          height: 15rem;
        }
      }
      .card-text {
        position: absolute;
        top: 1rem;
        z-index: 999;
        width: 100%;
        padding: 0.5rem;
        h3,
        h1 {
          padding: 0.5rem 1rem;
          color: white;
        }
        &_right {
          display: flex;
          margin-right: 5px;
        }
      }
    }
  }
  img.about {
    width: 100%;
  }
  @include respond("not-phone") {
    width: 40vw;
  }
}
