.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  background-color: white;
  width: 100vw;
  @media only screen and (min-width: 600px) {
    height: 60px;
  }
  .background-layer {
    border-top: 1px solid lightgray;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    @media only screen and (min-width: 600px) {
      width: 38.5vw;
      margin-left: 30.4%;
      height: 60px;
    }
    .item {
      padding-top: 3px;
      cursor: pointer;
      svg.footer-con {
        display: block;
        margin: auto;
      }
      p {
        font-size: 14px;
      }
      a {
        color: grey;
        text-decoration: none;
      }
    }
  }
}
