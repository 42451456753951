.offcanvas.offcanvas-bottom {
  height: 40%;
  @media only screen and (min-width: 600px) {
    width: 50%;
    margin-left: 25%;
  }
}

.pointer {
  cursor: pointer;
}
.Toastify {
  font-size: $font-medium;
}
select.form-select {
  font-size: $font-medium;
}
.margin-auto-v {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-auto-h {
  margin-left: auto;
  margin-right: auto;
}
.loading-spinners {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 150px;
  height: 100px;
}

.editable-div {
  border: 1px solid #ccc;
  padding: 5px;
  cursor: text;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s step-end infinite;
}
