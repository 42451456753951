html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  font-weight: 300;
  line-height: 1.6;
}
p {
  font-size: $font-medium;
}
span {
  font-size: $font-medium;
}

@include respond("phone") {
  input,
  select,
  textarea {
    font-size: 1.6rem !important;
  }
}
