.login {
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  img {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond("not-phone") {
    width: 30vw;
    img {
      width: 500px;
    }
  }
}
.register {
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  span.mr-3 {
    margin-right: 15px;
  }
  img {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond("not-phone") {
    width: 30vw;
    img {
      width: 500px;
    }
  }
}
