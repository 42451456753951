.form-control {
  display: block;
  width: 100%;
  padding: 0.675rem 0.95rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  @include respond("phone") {
    padding: 0.575rem 0.95rem;
  }
}
.dropdown {
  button {
    &.dropdown-toggle {
      font-size: 1.5rem;
      @include respond("phone") {
        font-size: 1.2rem;
      }
    }
  }
  a.dropdown-item {
    font-size: 1.5rem;
    @include respond("phone") {
      font-size: 1.2rem;
    }
  }
}
button {
  &.btn {
    padding: 0.5rem 2rem;
    font-size: $font-medium;
    @include respond("phone") {
      padding: 0.7rem 2rem;
      font-size: $font-small;
    }
  }
}
.col-form-label {
  font-size: $font-medium;
}
table {
  font-size: $font-medium;
  @include respond("phone") {
    font-size: $font-small;
  }
  span {
    font-size: $font-small;
  }
}
.list-group {
  font-size: $font-medium;
  .list-group-item {
    padding: 10px 20px;
  }
}
.offcanvas {
  width: 99.9vw;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 30px;
  p {
    font-size: 1.2rem;
  }
}
.offcanvas.offcanvas-bottom.show {
  height: 70vh;
}
button.verify {
  border-radius: 50px !important;
  background-color: rgba($color: #ffffff, $alpha: 0.5);
}
.custom-radio {
  padding: 10px;
}
.form-check.form-check-inline {
  margin-right: 40px;
}
label.form-check-label {
  font-size: 1.5rem;
}
