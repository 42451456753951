// Colors
$color-primary: #0d6efd;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;
$color-black: #000;
$color-white: #fff;
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;
$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;
$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;
$color-light-purple: #acbcff;
$color-header-background: #6096b4;
$color-pink: #faebeb;
$color-blood-red: #982229;

// Font Sizes
$font-x-small: 1rem;
$font-small: 1.2rem;
$font-normal: 1.4rem;
$font-medium: 1.6rem;
$font-large: 2rem;
$font-x-large: 2.5rem;

// Sizes
$space-1: 0.5rem;
$space-2: 1rem;
$space-3: 1.5rem;
$space-4: 2rem;
