.home {
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  font-size: $font-small;
  @include respond("not-phone") {
    width: 40vw;
    font-size: 1.6rem;
  }
  .header {
    background-color: $color-header-background;
    height: 50px;
    color: white;
    @include respond("not-phone") {
      height: 60px;
    }
    p.assets {
      margin-left: 20px;
      margin-top: 15px;
      margin-bottom: auto;
    }
    .change-language {
      color: white;
      margin-right: 10px;
      margin-top: 10px;
      @include respond("not-phone") {
        margin-top: 0;
      }
      span {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
      }
      @include respond("not-phone") {
        padding-top: 10px;
        padding-left: 50px;
      }
    }
  }
  &__menu {
    background-image: url("../../images/profile-bg.png") !important;
    background-size: cover;
    margin: 1rem;
    padding-top: 1rem;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    &-item {
      top: 0;
      align-self: flex-start;
      width: 25%;
      display: flex;
      flex-direction: column;
      &_icon {
        background-color: $color-tertiary-light;
        height: 5rem;
        width: 5rem;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
        text-align: center;
        @include respond("phone") {
          height: 4rem;
          width: 4rem;
        }
        svg {
          color: white;
          margin-top: 1rem;
          @include respond("phone") {
            margin-top: 0.5rem;
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: black;
      text-align: center;
      @include respond("phone") {
        font-size: 0.9rem;
      }
      p {
        margin-top: 0.5rem;
        font-size: 1.3rem;
        text-align: center;
        color: white;
        @include respond("phone") {
          font-size: 1.2rem;
        }
      }
    }
  }
  img.img-thumbnail.home__bg {
    width: 100%;
  }
  img.slide-img {
    height: 200px;
    width: 100%;
    @include respond("desktop") {
      height: 250px;
      width: 100%;
    }
    @include respond("big-desktop") {
      height: 350px;
      width: 100%;
    }
  }
  .info-card {
    span {
      margin-left: 15px;
    }
    .card {
      border-radius: 20px !important;
      margin: 2rem 1rem;
    }
  }
  .room-list {
    margin-bottom: 50px;
    img {
      cursor: pointer;
    }
  }
  &__country-list {
    img.flag-img {
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
      @include respond("phone") {
        height: 7rem;
        width: 7rem;
      }
    }
    .item {
      display: flex;
      flex-direction: row;
      margin: 2rem 5rem;
      @include respond("phone") {
        margin: 1rem 1rem;
      }
      h4 {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 1rem;
        color: white;
      }
    }
    .card {
      background-image: url("../../images/bg-image.jpg") !important;
      background-size: cover;
    }
  }
  &__trending {
    h3 {
      color: $color-tertiary-light;
    }
    &_item {
      margin-left: 1rem;
      display: flex;
      justify-content: space-between;
      img {
        width: 10rem;
        @include respond("phone") {
          width: 6rem;
        }
      }
      h4 {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}
