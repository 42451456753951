.topup {
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  @include respond("not-phone") {
    width: 40vw;
  }
  .card {
    border-radius: 0 !important;
  }
  button {
    margin-right: 5px !important;
  }
  h3.balance-text {
    color: white;
    margin-top: 10px;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .account-type {
    margin: 5px 5px 5px 20px;
    img {
      height: 70px;
    }
    h3 {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 5px;
    }
  }
  .detail-header {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
    img {
      height: 30px;
    }
    h6 {
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 5px;
    }
  }
  .form-container {
    @include respond("not-phone") {
      margin: 20px !important;
    }
  }
  .header {
    background-color: #7b8fa1;
    height: 60px;
    color: white;
    span.assets {
      margin-left: 5px;
    }
    .change-language {
      color: white;
      margin-right: 10px;
      margin-top: 10px;
      @include respond("not-phone") {
        margin-top: 0;
      }
      span {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
      }
      @include respond("not-phone") {
        padding-top: 10px;
        padding-left: 50px;
      }
    }
  }
  img.slide-img {
    height: 200px;
    width: 100%;
    @include respond("not-phone") {
      height: 300px;
      width: 100%;
    }
  }
  .info-card {
    span {
      margin-left: 15px;
    }
    .card {
      border-radius: 20px !important;
    }
  }
  .room-list {
    img {
      cursor: pointer;
    }
  }
}
