.user-profile {
  margin-left: 20px;
  &__avatar {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    img {
      height: 6rem !important;
      width: 6rem !important;
      border-radius: 50% !important;
      border: 2px solid #7ed56f;
    }
    svg {
      position: absolute;
      bottom: 0;
      left: 40px;
      cursor: pointer;
      @include respond("phone") {
        right: 10px;
      }
      @include respond("laptop") {
        right: 20px;
      }
    }
  }
  &__account {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.number-pad {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: space-between;
  align-items: center;
  &__button {
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 5px;
    &:active {
      background-color: #faebeb;
    }
    &_text {
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 2.5rem;
    }
  }
}
