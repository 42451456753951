//MEDIA QUERY MANAGER

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

@mixin respond($breakpoint) {
  @if ($breakpoint == "not-phone") {
    @media only screen and (min-width: 35.9375rem) {
      @content;
    } //575
  }
  @if ($breakpoint == "phone") {
    @media only screen and (max-width: 35.9375rem) {
      @content;
    } //575
  }

  @if ($breakpoint == "phone-land") {
    @media only screen and (max-width: 47.9375rem) {
      @content;
    } //767
  }

  @if ($breakpoint == "tab-port") {
    @media only screen and (max-width: 61.9375rem) {
      @content;
    } //991
  }

  @if ($breakpoint == "tab-land") {
    @media only screen and (max-width: 74.9375rem) {
      @content;
    } //1199
  }

  @if ($breakpoint == "laptop") {
    @media only screen and (min-width: 75rem) {
      @content;
    } //1200
  }

  @if ($breakpoint == "desktop") {
    @media only screen and (max-width: 87.5rem) {
      @content;
    } //1400
  }

  @if ($breakpoint == "big-desktop") {
    @media only screen and (min-width: 112.5rem) {
      @content;
    } //1800
  }
}
